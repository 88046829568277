import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = () => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Muck Boot Co</h2>
          <p>
            The Original Muck Boot Co. is dedicated to delivering boots and
            footwear that are 100% MUCKPROOF: remarkably protective,
            exceptionally comfortable, totally waterproof, and designed to brave
            every element for work (and life) in the Muck.
          </p>
          <p>
            Over the past few decades, we have had the freedom to innovate,
            experiment, and test our products in every single element—because
            outside, there are way more than four seasons. That's why we've
            designed each of our styles to work hard for every task and brave
            every condition—so you can be protected, productive, and always in
            your comfort zone, no matter what the weather.
          </p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-body/muck/muck-family-right.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{ width: `100%`, height: `100%` }}
            style={{ width: `100%`, height: `100%` }}
          />
        </div>
      </div>
      <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
          <StaticImage
            src="../../assets/images/index/brand-body/muck/muck-square-01.jpg"
            className={brandBodyStyles.halfImage}
            alt=""
            imgStyle={{
              width: `100%`,
              height: `100%`,
              padding: "20px 10px 10px 20px",
            }}
            style={{ width: `100%`, height: `100%` }}
          />
          <StaticImage
            src="../../assets/images/index/brand-body/muck/muck-square-02.jpg"
            className={brandBodyStyles.halfImage}
            alt=""
            imgStyle={{
              width: `100%`,
              height: `100%`,
              padding: "20px 20px 10px 10px",
            }}
            style={{ width: `100%`, height: `100%` }}
          />
          <StaticImage
            src="../../assets/images/index/brand-body/muck/muck-square-03.jpg"
            className={brandBodyStyles.halfImage}
            alt=""
            imgStyle={{
              width: `100%`,
              height: `100%`,
              padding: "10px 10px 20px 20px",
            }}
            style={{ width: `100%`, height: `100%` }}
          />
          <StaticImage
            src="../../assets/images/index/brand-body/muck/muck-square-04.jpg"
            className={brandBodyStyles.halfImage}
            alt=""
            imgStyle={{
              width: `100%`,
              height: `100%`,
              padding: "10px 20px 20px 10px",
            }}
            style={{ width: `100%`, height: `100%` }}
          />
        </div>
        <div className={brandBodyStyles.highlights}>
          <h3>Highlights</h3>
          <h4>Our Story</h4>
          <p>
            It all started with a universal problem—muck. Wet, messy, muddy,
            dirty muck, and we determined to find a solution for keeping our
            feet warm and dry through it all. So we set out to build the most
            protective, most comfortable, most reliable boots on the planet—no
            matter what the conditions. And The Original Muck Boot Company&reg;
            was born providing 100% waterproof boots and footwear that withstand
            the muckiest situations in every season.
          </p>
          <h4>Our Purpose</h4>
          <p>
            Muck is rooted in necessity, grounded in community, built to
            protect, worn with pride, and made for work (and life) in the muck.
          </p>
          <h4>100% MUCKPROOF</h4>
          <p>
            Fully protected from wet, windy, snow, mud, and sticky situations.
          </p>
        </div>
      </div>
      <div className={brandBodyStyles.strategy}>
        <h4>Muck Boot Company Strategy</h4>
        <p>
          We are dedicated to delivering boots and footwear that are 100%
          MUCKPROOF: remarkably protective, exceptionally comfortable, and
          totally waterproof, made with premium neoprene and rubber, and
          designed to brave every element.
        </p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link
            to="/customer-resources/join-our-family-of-authorized-dealers/"
            title="Become a Dealer"
            className="CTAblack"
          >
            Become a Dealer
          </Link>
          <Link
            to="/customer-resources/for-our-consumers/"
            title="Customer Resources"
            className="CTAalt"
          >
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
