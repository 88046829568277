import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/muckBrandHeader"
import BrandBody from "../../components/brand-bodies/muckBrandBody"
import { Helmet } from "react-helmet"

const MuckBootsPage = ({ pageContext, location }) => {
  const pageTitle = "The Original Muck Boot Company"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        The Original Muck Boot Co. is dedicated to delivering boots and footwear
        that are 100% MUCKPROOF: remarkably protective, exceptionally
        comfortable, totally waterproof, and designed to brave every element for
        work (and life) in the Muck.
      </p>
    </>
  )
}

export default MuckBootsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">The Original Muck Boot Co.</title>
    <meta
      name="description"
      content="The Original Muck Boot Co. is dedicated to delivering boots and
      footwear that are 100% MUCKPROOF: remarkably protective,
      exceptionally comfortable, totally waterproof, and designed to brave
      every element for work (and life) in the Muck."
    />
  </>
)
