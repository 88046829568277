import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandHeaderStyles from "./BrandHeader.module.scss"

const BrandHeader = ({}) => {
  return (
    <div className={brandHeaderStyles.wrapper}>
      <div className={brandHeaderStyles.heroImageWrapper} id="brand-header-hero">
        <StaticImage
          src="../../assets/images/index/brand-headers/muck/muck-header-mobile.jpg"
          className={brandHeaderStyles.heroImageMB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/muck/muck-header-mobile.jpg"
          className={brandHeaderStyles.heroImageTB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/muck/muck-header-desktop.jpg"
          className={brandHeaderStyles.heroImageDT}
          alt=""
        />
      </div>
      <div className={`brand-container ${brandHeaderStyles.title}`}>
        {/* just a placeholder til dynamic breadcrumb component is built */}
        <h1>Muck Boot Company</h1>
        <p>100% MUCKPROOF, 100% Waterproof. Designed for warmth &amp; comfort, no matter the terrain.</p>
        <div className={brandHeaderStyles.buttons}>
          <a class="CTAblack" href="https://www.muckbootcompany.com/Home" title="Muck Boot Co">Visit Website</a>
          {/* <a class="CTAalt" href="" title="">Watch Brand Video</a> */}
        </div>
      </div>
    </div>
  )
}

export default BrandHeader
